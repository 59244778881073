<template>
  <div>
    <!-- Header -->
    <header>
      <div class="container">
        <router-link class="logo ml-2" :to="{ name: 'home' }">
          <img src="@/assets/duplioAsset/duplioAssets/golfigo3.png" />
        </router-link>

        <nav class="mr-2">
          <ul :class="[{ active: openDrawer }, 'menu']">
            <li v-for="(item, index) in menuLink" :key="index">
              <router-link tag="a" :to="{ name: item.routeName }">
                {{ item.label }}
              </router-link>
            </li>

            <li class="ml-0"><Locale /></li>

            <li>
              <router-link tag="a" :to="{ name: 'login' }" class="button">
                {{ $t("NavbarFirst.login") }}
              </router-link>
            </li>
          </ul>
          <a
            href="#"
            @click="openDrawer = !openDrawer"
            :class="[{ active: openDrawer }, 'menu-trigger']"
          >
            <span v-for="data in 3" :key="data"></span>
          </a>
        </nav>
      </div>
    </header>
    <!-- Header End -->
  </div>
</template>

<script>
import Locale from "./components/Locale.vue";

export default {
  components: {
    Locale,
  },
  data() {
    return {
      openDrawer: false,
      menuLink: [
        {
          label: this.$t("NavbarFirst.about"),
          routeName: "about",
        },
        {
          label: this.$t("NavbarFirst.contact"),
          routeName: "contact",
        },
        {
          label: this.$t("NavbarFirst.blog"),
          routeName: "blog",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/firstlayout/header.scss";
</style>
