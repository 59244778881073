<template>
    <div>
        <AppNavbarFirst />
        <router-view />
        <Footer />
    </div>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue';
import AppNavbarFirst from '@core/layouts/components/app-navbar/AppNavbarFirst.vue';
import Footer from '@core/layouts/components/footer';

export default {
    components: {
        LayoutFull,
        Footer,
        AppNavbarFirst,
    },
};
</script>
